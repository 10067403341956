import styled from 'styled-components';

import variables from '@splunk/themes/variables';

import { FC, PropsWithChildren, ReactNode } from 'react';

import { SubHeadSmall, SubSectionH3 } from '../../styles/typography';
import CustomizedButton from '../CustomizedButton/CustomizedButton';

const StyledBox = styled.div`
  padding: 40px 0;
`;

const StyledContent = styled.div`
  width: 1250px;
  margin: 0 auto;
`;

const StyledSubHeadSmall = styled(SubHeadSmall)`
  color: ${variables.contentColorDefault};
  margin: 30px 0px 30px 45px;
`;

const TopRow = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledIcon = styled.span`
  color: ${variables.contentColorDefault};
  margin-right: 16px;
`;

const HeadWrapper = styled.div`
  display: flex;
  margin-left: 45px;
`;

export interface HomeSectionProps extends PropsWithChildren {
  icon?: ReactNode;
  backgroundColor?: string;
  head?: string;
  subhead?: string;
  link?: string;
}

const HomeSection: FC<HomeSectionProps> = ({ icon, backgroundColor, children, head, subhead, link }) => {
  return (
    <StyledBox style={{ backgroundColor }}>
      <StyledContent>
        <TopRow>
          <HeadWrapper>
            <StyledIcon>{icon}</StyledIcon>
            <SubSectionH3 style={{ margin: 'auto' }}>{head}</SubSectionH3>
          </HeadWrapper>
          {link && (
            <CustomizedButton
              style={{
                color: '#0086FF',
                fontSize: '18px',
                marginRight: '48px',
              }}
              appearance="secondary"
              to={link}
              label="See All"
              data-test="seeall"
            />
          )}
        </TopRow>
        <StyledSubHeadSmall>{subhead}</StyledSubHeadSmall>
        {children}
      </StyledContent>
    </StyledBox>
  );
};

export default HomeSection;
