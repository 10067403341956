import styled from 'styled-components';

import { AppCardPlatform } from '../../styles/typography';
import RatingStars from '../RatingStars/RatingStars';

export interface RatingProps {
  average: number;
  total: number;
}

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  fontsize: 30px;
`;

const StyledText = styled(AppCardPlatform)`
  padding-left: 5px;
`;

const Rating: React.FC<RatingProps> = ({ average, total }) => {
  return (
    <StyledDiv>
      <RatingStars average={average} />
      <StyledText> ({total})</StyledText>
    </StyledDiv>
  );
};

export default Rating;
