import Button from '@splunk/react-ui/Button';
import SplunkThemeProvider from '@splunk/themes/SplunkThemeProvider';
import { AnyTheme } from '@splunk/themes/types/types';

function customizeColor(theme: AnyTheme) {
  return {
    ...theme,
    interactiveColorPrimary: '#C2006B',
    interactiveColorBackground: '#C2006B',
    interactiveColorBorder: '#C2006B',
  };
}

function Themed(props: Record<string, string | object>) {
  return (
    <div>
      <SplunkThemeProvider family="prisma" customizeTheme={customizeColor}>
        <Button {...props} />
      </SplunkThemeProvider>
    </div>
  );
}

export default Themed;
