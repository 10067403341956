import Star from '@splunk/react-icons/Star';

export interface RatingProps {
  average: number;
}

const RatingStars: React.FC<RatingProps> = ({ average }) => {
  const ratedStars = [1, 2, 3, 4, 5].map((i) => {
    const variant = i <= average ? 'filled' : 'default';
    return <Star data-test={variant} key={i} variant={variant} />;
  });

  return <div aria-label={'rated ' + average.toString() + ' stars'}>{ratedStars}</div>;
};

export default RatingStars;
