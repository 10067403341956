import styled from 'styled-components';

import Card from '@splunk/react-ui/Card';
import Link from '@splunk/react-ui/Link';
import variables from '@splunk/themes/variables';

import useSubmitNewAppUrl from '../../lib/hooks/useSubmitNewAppUrl';
import { getResourceUrl } from '../../lib/resource_helper';
import { BodySmall, SubHeadLarge } from '../../styles/typography';
import CustomizedButton from '../CustomizedButton/CustomizedButton';

const StyledCard = styled(Card)`
  background: url(${getResourceUrl('/home/build_app_banner.png')}) no-repeat center;
  background-position: left 100% bottom 0%;
  background-size: 23%;
  border: 1px solid #393b41;
  padding: 32px 380px 32px 32px;
  width: 1110px;
  height: 250px;
  border-radius: 4px;
`;

const StyledTitle = styled(SubHeadLarge)`
  line-height: 33px;
  font-weight: ${variables.fontWeightSemiBold};
`;

const StyledDescription = styled(BodySmall)`
  line-height: 21px;
  margin-top: 20px;
`;

const SubmitYourAppBanner = () => {
  const submitNewAppUrl = useSubmitNewAppUrl('/profile');

  return (
    <StyledCard>
      <StyledTitle>Not finding the perfect app? Build it!</StyledTitle>
      <StyledDescription>
        As a Splunkbase app developer, you will have access to all Splunk development resources and receive a 10GB
        license to build an app that will help solve use cases for customers all over the world. Splunkbase has 1000+
        apps and add-ons from Splunk, our partners and our community.{' '}
      </StyledDescription>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '30px',
        }}
      >
        <CustomizedButton
          appearance="primary"
          style={{
            color: '#FFFFFF',
            fontSize: '15px',
            marginRight: '23px',
          }}
          label="Submit Your App"
          to={submitNewAppUrl}
        />
        <Link
          style={{
            fontSize: '18px',
          }}
          openInNewContext
          to={`https://dev.splunk.com/enterprise/docs/welcome`}
        >
          Read Documentation
        </Link>
      </div>
    </StyledCard>
  );
};

export default SubmitYourAppBanner;
