import styled from 'styled-components';

export interface PaginationProps {
  key: string | number;
  onClick: () => void;
  active: boolean;
  visible: boolean;
}
export default styled.div<PaginationProps>`
  cursor: pointer;
  transition: all 250ms ease-in;
  background-color: grey;
  background-image: ${({ active }) =>
    active
      ? 'linear-gradient(90deg, rgba(249, 157, 28, 1) 9.36%, rgba(244, 120, 31, 1) 21.65%, rgba(241, 98, 33, 1) ' +
        '31.74%, rgba(240, 90, 34, 1) 38.16%, rgba(238, 29, 98, 1) 64.84%, rgba(237, 0, 128, 1) 76.34%)'
      : 'transparent'};
  color: ${({ active }) => (active ? '#fff' : '333')};
  transform: scale(${({ active }) => (!active ? 0.8 : 1)});
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 15px !important;
  height: 15px;
  border: none;
  margin: 5px;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;
