import styled from 'styled-components';

import Button from '@splunk/react-ui/Button';
import Card from '@splunk/react-ui/Card';
import variables from '@splunk/themes/variables';

import Image from 'next/image';

import React, { FC } from 'react';

import imageLoaderFactory from '../../lib/image_loader_factory';
import { getResourceUrl } from '../../lib/resource_helper';
import { BodySmall, SubSectionH3 } from '../../styles/typography';
import type { Collection } from '../../types/splunkbase_types';

const StyledCard = styled(Card)`
  padding: 32px 0 0 32px;
  position: relative;
  overflow: hidden;
  width: 540px;
  height: 330px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.35), 0px 0px 1px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  border: 0.5px solid #33343b;
`;

const StyledBody = styled.div`
  padding-bottom: 32px;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 266px 211px;
`;

const StyledContent = styled.div`
  display: flex;
  grid-column-start: 1;
  display: flex;
  flex-flow: row wrap;
  flex-direction: column;
`;

const StyledTitle = styled(SubSectionH3)`
  line-height: 33px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StyledAppsCount = styled.div`
  font-size: 12px;
  font-weight: ${variables.fontWeightSemiBold};
  text-transform: uppercase;
  color: ${variables.contentColorMuted};
  margin: 0px;
  padding: 10px 0;
`;

const StyledP = styled(BodySmall)`
  line-height: 21px;
  color: ${variables.contentColorActive};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`;

const StyledButton = styled.div`
  position: absolute;
  left: 32px;
  bottom: 32px;
`;

const StyledImage = styled.div`
  position: absolute;
  right: 0;
  bottom: -5px;
  grid-column-start: 2;
`;

const CollectionCard: FC<{ collection: Collection }> = ({ collection }) => {
  return (
    <StyledCard key={collection.id}>
      <StyledBody>
        <StyledContent>
          <StyledTitle>{collection.display_name}</StyledTitle>
          <StyledAppsCount>{collection.app_count + ' solutions'}</StyledAppsCount>
          <StyledP>{collection.tagline} </StyledP>
          <StyledButton>
            <Button label="View Apps" to={`/collections/${collection.name}`} />
          </StyledButton>
        </StyledContent>
        <StyledImage>
          {collection.icon_url && (
            <Image
              src={getResourceUrl(collection.icon_url)}
              width="209"
              height="330"
              alt="Collection icon"
              loader={imageLoaderFactory(collection.icon_url)}
            />
          )}
        </StyledImage>
      </StyledBody>
    </StyledCard>
  );
};

export default CollectionCard;
