import styled from 'styled-components';

import { getResourceUrl } from '../../lib/resource_helper';
import { PageContainer } from '../../styles/layout';
import { HeroTitle } from '../../styles/typography';
import NavBarSearchHome from '../NavBarSearchHome/NavBarSearchHome';

export interface HomeIntroProps {
  pageInfo: {
    name: string;
  };
}

const StyledPageContainer = styled(PageContainer)`
  margin-top: -60px;
  min-height: 473.04px;
`;
const PageInfo = styled.div`
  margin-right: 2rem;
  margin-top: 20px;
  min-width: 560px;
  h1 {
    min-height: 130px;
  }
`;
const StyledImage = styled.video`
  width: 640px;
  margin-left: -10px;
  object-fit: cover;
`;

const HomeIntro: React.FC<HomeIntroProps> = ({ pageInfo }) => {
  return (
    <StyledPageContainer>
      <PageInfo>
        <HeroTitle>{pageInfo.name}</HeroTitle>
        <NavBarSearchHome />
      </PageInfo>
      <StyledImage autoPlay webkit-autoPlay muted loop webkit-playsinline id="heroVideo">
        <source src={getResourceUrl('/home/hero.mov')} type='video/mp4; codecs="hvc1"'></source>
        <source src={getResourceUrl('/home/hero_half.mov')} type='video/mp4; codecs="hvc1"'></source>
        <source src={getResourceUrl('/home/hero.webm')} type="video/webm"></source>
      </StyledImage>
    </StyledPageContainer>
  );
};

export default HomeIntro;
